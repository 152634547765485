@font-face {
  font-family: KH Teka;
  font-weight: 300;
  font-style: normal;
  src: url("KHTeka-Light.21209ca8.woff2") format("woff2"), url("KHTeka-Light.8359c651.woff") format("woff");
  unicode-range: U+??, U+2082;
}

@font-face {
  font-family: KH Teka;
  font-style: italic;
  font-weight: 300;
  src: url("KHTeka-LightItalic.b0172687.woff2") format("woff2"), url("KHTeka-LightItalic.a0192de0.woff") format("woff");
  unicode-range: U+??, U+2082;
}

@font-face {
  font-family: KH Teka;
  font-style: normal;
  font-weight: normal;
  src: url("KHTeka-Regular.b255c4ed.woff2") format("woff2"), url("KHTeka-Regular.8642b659.woff") format("woff");
  unicode-range: U+??, U+2082;
}

@font-face {
  font-family: KH Teka;
  font-style: italic;
  font-weight: normal;
  src: url("KHTeka-RegularItalic.8d072dd9.woff2") format("woff2"), url("KHTeka-RegularItalic.27aa5b3e.woff") format("woff");
  unicode-range: U+??, U+2082;
}

@font-face {
  font-family: KH Teka;
  font-style: normal;
  font-weight: 500;
  src: url("KHTeka-Medium.b2dcc027.woff2") format("woff2"), url("KHTeka-Medium.dd49214b.woff") format("woff");
  unicode-range: U+??, U+2082;
}

@font-face {
  font-family: KH Teka;
  font-style: italic;
  font-weight: 500;
  src: url("KHTeka-MediumItalic.af512935.woff2") format("woff2"), url("KHTeka-MediumItalic.ed44dc90.woff") format("woff");
  unicode-range: U+??, U+2082;
}
/*# sourceMappingURL=index.3338caf3.css.map */
